<template>
  <b-form-checkbox-group v-model="selected"
                         :size="size"
                         :stacked="stacked"
                         buttons
                         class="lights-btn-group">
    <b-form-checkbox v-for="(option, idx) in options"
                     :key="idx"
                     v-b-tooltip.hover.leftbottom="labeledLights ? '' : option.value"
                     :button-variant="variant(option)"
                     :disabled="disabled"
                     :value="option.value"
                     class="lights-btn">
    <span v-if="labeledLights"
          v-text="option.text"/>
    </b-form-checkbox>
  </b-form-checkbox-group>
</template>

<script>
  export default {
    name: 'traffic_lights_buttons',
    props: {
      value: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      stacked: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      labeledLights: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'sm',
      },
    },
    data() {
      return { selected: [this.value] };
    },
    computed: {
      lastSelectedItem() {
        return this.selected[this.selected.length - 1] || '';
      },
    },
    methods: {
      variant(option) {
        if (this.disabled && option.value === this.value) {
          return option.disabledVariant;
        }
        return option.buttonVariant;
      },
    },
    watch: {
      value() {
        this.selected = [this.value];
      },
      selected() {
        this.$emit('input', this.lastSelectedItem);
      },
    },
  };
</script>

<style scoped>

  .lights-btn {
    min-height: 1em;
  }

  .lights-btn-group {
    min-height: 100%;
  }

</style>
